import React from 'react'
import {Box, Typography} from '@mui/material'
import { useParams } from 'react-router-dom'
export default function Failure() {
    const {reason} = useParams()
    return (
        <Box sx={{backgroundColor: 'black', color:'white', width: '100vw', height:'800px',margin:'auto', textAlign:'center', overflowY: "hidden"}}>
            <Typography sx={{pt:10, mb:2,}} variant="h4" color='white'>Failure</Typography>
            <Typography sx={{}} variant='h6' color='white'>{reason}</Typography>
            <Typography sx={{}} variant='h6' color='white'>Your order is {reason}</Typography>
            <Typography sx={{mt: 10}} variant='h6' color='white'>Redirecting ....</Typography>
        </Box>
    )
}