import * as React from 'react';
import MediaControlCard from "../components/ItemList/ItemList";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAllItems, getAllVMSlotsItems, getVMById } from '../apis/routes';
import { TextField, Box } from '@mui/material';
import Breakdown from './Breakdown';
import Cart from '../components/Cart';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from "@mui/material";
import outOfServiceImage from "../images/out.jpeg";


export default function LocalMachine() {
    const { id } = useParams();

    const [itemData, setItemData] = useState(() => []); // Items present in the VM
    const [initialItemData, setInitialItemData] = useState(() => []);
    const [cartValue, setCartValue] = useState(0);
    const [selectedItems, setSelectedItems] = useState({}); // Items added to the cart by user
    const [loading, setLoading] = useState(true);
    const [offline, setOffline] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [isRack, setIsRack] = useState(false); // State for rack status

    const updateItemResponseData = (items, vmItems) => {
        const vmIds = vmItems.filter(item => item.quantity > 0).map(item => item.item_id_id);
        const data = items.filter(item => vmIds.includes(item.guid));
        data.forEach((item, index) => {
            const matched = vmItems.find(i => i.item_id_id === item.guid);
            data[index]['stock'] = matched.quantity;
            data[index]['slot'] = matched.slot_id;
            data[index]['selected_count'] = 0; // Initialize selected count to 0
        });
        return data;
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const vmDetails = await getVMById(id);
            if (vmDetails.status !== 10) {
                setOffline(true);
                return;
            }
            setIsRack(vmDetails.metadata?.is_rack === 0); // Set isRack based on metadata

            const items = await getAllItems();
            const vmSlotItems = await getAllVMSlotsItems(id);
            const iData = updateItemResponseData(items, vmSlotItems);
            setInitialItemData(iData);
            setItemData(iData);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const updateCartValue = (action, selectedItemDetails) => {
        console.log(">>>>>>>>>>>", isRack, selectedItemDetails);
        if (isRack) {
            // Deselect all items and select only the current one
            const updatedItems = initialItemData.map((item) => {
                if (item.guid === selectedItemDetails.id) {
                    return {
                        ...item,
                        selected_count: action === 'add' ? 1 : 0,
                    };
                }
                return { ...item, selected_count: 0 }; // Reset all other items
            });
            console.log("++++++RACK+++++", updatedItems)
            // setInitialItemData(updatedItems);
            setItemData(updatedItems);

            if (action === 'add') {
                setCartValue(1); // Only one item allowed
                setSelectedItems({
                    [selectedItemDetails.id]: {
                        count: 1,
                        price: selectedItemDetails.totalPrice,
                        tax: selectedItemDetails.tax,
                        name: selectedItemDetails.name,
                        slot: selectedItemDetails.slot,
                    },
                });
            } else {
                setCartValue(0); // Clear cart when removing
                setSelectedItems({});
            }
            return;
        }

        // For non-rack vending machines
        if (action === 'add') {
            setCartValue(cartValue + 1);
        } else if (action === 'remove') {
            setCartValue(cartValue - 1);
        }

        const updatedItems = initialItemData.map((item) => {
            if (item.guid === selectedItemDetails.id) {
                return { ...item, selected_count: selectedItemDetails.selectedCount };
            }
            return item;
        });

        setInitialItemData(updatedItems);

        if (selectedItems.hasOwnProperty(selectedItemDetails.id)) {
            const updatedSelectedItems = { ...selectedItems };
            updatedSelectedItems[selectedItemDetails.id] = {
                count: selectedItemDetails.selectedCount,
                price: selectedItemDetails.totalPrice,
                tax: selectedItemDetails.tax,
                name: selectedItemDetails.name,
                slot: selectedItemDetails.slot,
            };
            setSelectedItems(updatedSelectedItems);
        } else {
            setSelectedItems({
                ...selectedItems,
                [selectedItemDetails.id]: {
                    count: selectedItemDetails.selectedCount,
                    price: selectedItemDetails.totalPrice,
                    tax: selectedItemDetails.tax,
                    name: selectedItemDetails.name,
                    slot: selectedItemDetails.slot,
                },
            });
        }
    };

    const updateCheckoutStatus = (status) => {
        setCheckout(status);
    };

    const handleChange = (e) => {
        const name = e.target.value;
        if (name === '') {
            setItemData(initialItemData);
            return;
        }
        const filteredData = initialItemData.filter(i => i.item_name.toLowerCase().includes(name.toLowerCase()));
        setItemData(filteredData.length < 1 ? initialItemData : filteredData);
    };

    if (offline) {
        return (
            <Box
                sx={{
                    textAlign: "center",
                    bgcolor: "white",
                    color: "black",
                    p: 2,
                    position: 'fixed',
                    top: 20,
                    width: '100%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box sx={{ bgcolor: "#e0e0e0", width: "100%", p: 1 }}>
                    <Typography variant="body1" fontWeight="bold">
                        Machine is out of service
                    </Typography>
                </Box>
    
                <img 
                src={outOfServiceImage} 
                alt="Out of Service" 
                style={{ width: "250px" }} 
            />

    
                {/* <Typography variant="h6" fontWeight="bold">
                    If the machine is ON <br /> and this error shows <br /> 
                    Please refresh/reload the browser page or <br />
                    scan again after few minutes
                </Typography> */}
    
                {/* <Typography variant="body1" mt={1}>
                    Please refresh/reload <br />
                    the browser page or <br />
                    scan again after few minutes
                </Typography> */}
            </Box>
        );
    }

    return (
        !checkout ? !loading && itemData.length > 0 &&
            <div style={{ marginBottom: 60, marginTop: 60 }}>
                <Box
                    sx={{
                        textAlign: "center",
                        bgcolor: "#e0e0e0",
                        color: "white",
                        p: 1,
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        zIndex: 2
                    }}
                >
                    <TextField
                        id="standard-search"
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SearchIcon style={{ marginRight: 4 }} />
                                <span style={{ fontSize: '1.1rem' }}>Search Item</span>
                            </div>
                        }
                        type="search"
                        variant="standard"
                        onChange={handleChange}
                        sx={{ margin: "auto" }}
                        size="small"
                    />
                </Box>
                {itemData.map((i) => (
                    <MediaControlCard
                        item={{
                            id: i.guid,
                            name: i.item_name,
                            price: i.item_price,
                            stock: isRack? 1 :i.stock,
                            desc: i.metadata?.description,
                            image: i.metadata?.image,
                            tax: i.tax,
                            slot: i.slot,
                            selected_count: i.selected_count
                        }}
                        callback={updateCartValue}
                    />
                ))}
                <Cart count={cartValue} id={id} callback={updateCheckoutStatus} />
            </div>
            : <Breakdown added_data={selectedItems} vm_id={id} />
    );
}
