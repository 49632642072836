import * as React from 'react';
import MediaControlCard from "../components/ItemList/ItemList";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAllItems, getAllVMSlotsItems, getVMById } from '../apis/routes'
import { TextField, Box } from '@mui/material'
import Breakdown from './Breakdown';
import Cart from '../components/Cart';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
export default function LocalMachine() {
    const { id } = useParams();

    const [itemData, setItemData] = useState(() => []); // items present in the vm
    const [initialItemData, setInitialItemData] = useState(() => []); 
    const [cartValue, setCartValue] = useState(0)
    const [selecetdItems, setSelectedItems] = useState({}) // items added to card by user
    const [slotItems, setSlotItems] = useState({}) // slot and item details of the vm
    const [loading, setLoading] = useState(true);
    const [offline, setOffline] = useState(false);
    const [checkout, setCheckout] = useState(false);

    const updateItemResponseData = (items, vm_items) =>{
        const vm_ids = vm_items.filter(item=>item.quantity > 0).map(item=>item.item_id_id)
        const data = items.filter(item => vm_ids.includes(item.guid)) ;
        data.forEach((item, index) => {
            const matched = vm_items.find(i => i.item_id_id == item.guid)
            data[index]['stock'] = matched.quantity
            data[index]['slot'] = matched.slot_id
            data[index]['selected_count'] = 0
        })
        console.log("-------VMDATA-------", data)
        return data
    }


    const fetchData = async () => {
        setLoading(true);
        try {
            const vm_details = await getVMById(id)
            if (vm_details.status !== 10) {
                setOffline(true);
                return;
            }
            const items = await getAllItems();
            const vm_slot_items = await getAllVMSlotsItems(id)
            const i_data = (updateItemResponseData(items, vm_slot_items))
            setInitialItemData(i_data)
            setItemData(i_data);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        console.log("+++++++++fetchData++++++++");
    }, []);


    const updateCartValue = (action, selectedItemDetails) => {
        console.log(">>>>>Callback called")
        if (action === 'add')
            setCartValue(cartValue + 1);
        else if (action === 'remove')
            setCartValue(cartValue - 1);

        initialItemData.forEach((item, index) => {
            if(selectedItemDetails.id == item.guid) {
                console.log("+++++SELECTED+++++++", item.id)
                initialItemData[index]['selected_count'] = selectedItemDetails.selectedCount
                setInitialItemData(initialItemData)
            }
        })

        if (selecetdItems.hasOwnProperty(selectedItemDetails.id)) {
            selecetdItems[selectedItemDetails.id]["count"] = selectedItemDetails.selectedCount
            selecetdItems[selectedItemDetails.id]["price"] = selectedItemDetails.totalPrice
            selecetdItems[selectedItemDetails.id]["tax"] = selectedItemDetails.tax
            selecetdItems[selectedItemDetails.id]["name"] = selectedItemDetails.name
            selecetdItems[selectedItemDetails.id]["slot"] = selectedItemDetails.slot
            setSelectedItems(selecetdItems);
        } else {
            setSelectedItems({...selecetdItems, [selectedItemDetails.id]:{
                count: selectedItemDetails.selectedCount, 
                price:selectedItemDetails.totalPrice,
                tax:selectedItemDetails.tax,
                name:selectedItemDetails.name,
                slot:selectedItemDetails.slot,
            } })
        }
        console.log(">>>>>>>>", initialItemData)
    }

    const updateCheckoutStatus = (status) => {
        setCheckout(status)
    }

    const handleChange = (e) => {
        console.log({e}, e.target, e.target.value)
        const name = e.target.value
        if (name == '') {
            setItemData(initialItemData)
            return
        }
        const filteredData = initialItemData.filter(i => i.item_name.toLowerCase().includes(name.toLowerCase()))
        if (filteredData.length < 1) {
            setItemData(initialItemData)
            return
        }
        console.log(filteredData)
        setItemData(filteredData)
    }

    if (offline) {
        return <Box sx={{ textAlign: "center", bgcolor: "#e0e0e0", color: "black", p: 1, position: 'fixed',
            top: 20, width: '100%',}}>
                Machine is out of service
        </Box>
    }

    return (
        !checkout ? !loading && itemData.length>0 && 
        <div style={{marginBottom:60, marginTop:60}}>
            <Box
             
                    // display: 'flex',
                    // flexDirection: 'column',
                    // '& .MuiTextField-root': { width: '25ch' },
            //         textAlign: "center", p: 1, position: 'fixed',
            // top: 0, width: '100%'
            sx={{ textAlign: "center", bgcolor: "#e0e0e0", color: "white", p: 1, position: 'fixed',
                top: 0, width: '100%', zIndex: 2 }}
            >
                {/* <TextField
                    id="standard-search"
                    label="Search Item"
                    type="search"
                    variant="standard"
                    onChange={handleChange}
                    sx={{margin:"auto"}}
                    size="small"
                /> */}
                <TextField
                    id="standard-search"
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SearchIcon style={{ marginRight: 4 }} />
                            <span style={{ fontSize: '1.1rem' }}>Search Item</span>
                            
                        </div>
                    }
                    type="search"
                    variant="standard"
                    onChange={handleChange}
                    sx={{ margin: "auto" }}
                    size="small"
                />
            </Box>
            {itemData.map((i) => (
                // console.log("--------I------", i);
                <MediaControlCard item={{id: i.guid, name: i.item_name, price: i.item_price, stock: i.stock, desc: i.metadata?.description, image: i.metadata?.image, tax: i.tax, slot: i.slot, selected_count: i.selected_count}}  callback={updateCartValue} />

            ))}
            <Cart count={cartValue} id={id} callback={updateCheckoutStatus} />
        </div> : <Breakdown added_data={selecetdItems} vm_id={id}/>
     
    );
}