import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react'
import Home from './pages/Home';
import Login from './pages/LoginPage';
import Shipping from './pages/Shipping';
import Cancel from './pages/Cancel';
import Condition from './pages/Conditions';
import Contact from './pages/Contact';
import Product from './pages/Product';
import LocalMachine from './pages/LocalMachine';
import Privacy from './pages/privacy';
import Failure from './pages/Failure';
// import { isLoggedIn } from './utils/utils';
import Users from './pages/Users/Users';
import Success from './pages/Success';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
function App() {
  return (
    <BrowserRouter>
        {/* <nav>
          <ul>
            <li><Link to="/manatee">Manatee</Link></li>
            <li><Link to="/narwhal">Narwhal</Link></li>
            <li><Link to="/whale">Whale</Link></li>
            <li><Link to="/whale/beluga">Beluga Whale</Link></li>
            <li><Link to="/whale/blue">Blue Whale</Link></li>
          </ul>
        </nav> */}
        <Routes history={history}>
          <Route path="/" element={<Home/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/product" element={<Product/>}/>
          <Route path="/conditions" element={<Condition/>}/>
          <Route path="/cancel" element={<Cancel/>}/>
          <Route path="/shipping" element={<Shipping/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/success/:order_id" element={<Success/>}/>
          <Route path="/failure/:order_id" element={<Failure/>}/>
          <Route path="/vm/:id" element={<LocalMachine/>}/>
          <Route path="/vm/:id/checkout" element={<LocalMachine/>}/>
          <Route path="/home/:user" element={<Users />} />
          {/* <Route path="/machine/:machine_id" element={<MachineDetails />} /> */}
        </Routes>
      </BrowserRouter>
  );
}

export default App;
